import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import Sections from '../components/Sections';
import Section from '../components/Section';
import PreviewCompatibleImage from '../components/PreviewCompatibleImage';

import styles from './about.module.scss';

const AboutPage = ({ data, location: { pathname, state } }) => {
  const {
    markdownRemark: {
      frontmatter: { meta, cta, introduction, sections, network },
    },
  } = data;

  return (
    <Layout className={styles.root} pathname={pathname} meta={meta} state={state}>
      <section className="section">
        <div className={`container ${styles.introduction}`}>
          <div className="content" dangerouslySetInnerHTML={{ __html: introduction.body }} />
          <PreviewCompatibleImage alt="Carla Emrich" imageInfo={introduction.image} />
        </div>
      </section>
      <Sections inverted sections={sections} />
      {cta && cta.body ? <Section {...cta} /> : null}
      <section className="section">
        <div className={`container ${styles.network}`}>
          <div className="content" dangerouslySetInnerHTML={{ __html: network.body }} />
          <div>
            {network.people.map(({ body, image }, i) => {
              return (
                <div key={`network_${i}`}>
                  <PreviewCompatibleImage className={`is-rounded ${styles.rounded}`} alt={body} imageInfo={image} />
                  <div className="content" dangerouslySetInnerHTML={{ __html: body }} />
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </Layout>
  );
};

AboutPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.shape({
        meta: PropTypes.shape({
          title: PropTypes.string,
          description: PropTypes.string,
        }).isRequired,
        cta: PropTypes.shape({
          body: PropTypes.string,
          button: PropTypes.shape({
            text: PropTypes.string,
            url: PropTypes.string,
          }),
        }),
        sections: PropTypes.arrayOf(
          PropTypes.shape({
            title: PropTypes.string,
            body: PropTypes.string,
            image: PropTypes.object,
            button: PropTypes.shape({
              text: PropTypes.string,
              url: PropTypes.string,
            }),
          }).isRequired
        ),
        introduction: PropTypes.shape({
          body: PropTypes.string,
          image: PropTypes.object,
        }),
        network: PropTypes.shape({
          body: PropTypes.string,
          people: PropTypes.arrayOf(
            PropTypes.shape({
              body: PropTypes.string,
              image: PropTypes.object,
            }).isRequired
          ),
        }),
      }),
    }),
  }),
};

export const aboutPageQuery = graphql`
  query aboutPage {
    markdownRemark(frontmatter: { templateKey: { eq: "about-page" } }) {
      frontmatter {
        meta {
          title
          description
        }
        introduction {
          body
          image {
            childImageSharp {
              fluid(maxWidth: 640, quality: 95) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        sections {
          title
          body
          image {
            childImageSharp {
              fluid(maxWidth: 640, quality: 95) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        cta {
          body
          button {
            text
            url
          }
        }
        network {
          body
          people {
            body
            image {
              childImageSharp {
                fluid(maxWidth: 480, quality: 95) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default AboutPage;
